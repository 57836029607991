import React, { useState } from 'react';
import Footer from '../../layouts/Footer';
import Cta from '../home/Cta';
import GeneralNavBar from '../../layouts/GeneralNavBar';
import Logo from '../../libs/assets/logo2_3.png';
import style from './Blog.module.scss';
import BlogCard from './BlogCard';
import posts from './Blog.js';
import image from './BlogAsset/blog7.jpg';
import calendar from '../../libs/assets/images/calendar.png';
import clock from '../../libs/assets/images/clock.png';
import forward from '../../libs/assets/images/forward.svg';
import forward1 from '../../libs/assets/images/forward.png';
import backward from '../../libs/assets/images/backward.svg';
import { useHistory } from 'react-router-dom';
import frame from './BlogAsset/FrameBlog.png';
import frame1 from './BlogAsset/FrameBlog1.png';

const BlogPage = ({ itemsPerPage = 9 }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [showFullContent, setShowFullContent] = useState(false);
  const history = useHistory();

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const nextPage = () => {
    if (currentPage < Math.ceil(posts.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const featuredBlogPost = currentPosts[0];

  const redirectToFullBlog = () => {
    history.push({
      pathname: `/blog/${featuredBlogPost.id}`,
      state: {
        title: featuredBlogPost.title,
        content: featuredBlogPost.content,
        image: featuredBlogPost.image,
      },
    });
  };

  return (
    <div>
      <GeneralNavBar linkColor={'black'} logoImage={Logo} />
      <div className={style['blog-header-container']}>
        <div className={style['blog-header-frame']}>
          <img src={frame} alt="frame" className={style['blog-frame']} />
          <img src={frame1} alt="frame" className={style['blog-frame1']} />
        </div>
        <h1>Awabah’s Blog</h1>
        <p>Empower Your Financial Journey with Our Insights</p>
      </div>
      <div className={style['featured-container']}>
        <h1 className={style['featured-content-h1']}>Featured blog posts</h1>
        <div className={style['featured-content']}>
          <img src={image} alt={featuredBlogPost.title} />
          <div>
            <h1>{featuredBlogPost.title}</h1>
            <p>
              {showFullContent
                ? featuredBlogPost.content
                : featuredBlogPost.content.substring(0, 200)}
              ...
            </p>
            <span>
              <img
                src={calendar}
                alt="calendar"
                style={{ width: '24px', height: '24px' }}
              />{' '}
              11 Sep 2023
              <img
                src={clock}
                alt="time"
                style={{ width: '24px', height: '24px' }}
              />{' '}
              5 min read
            </span>
            {featuredBlogPost.content.length > 200 && !showFullContent && (
              <span>
                <button onClick={() => redirectToFullBlog()}>Read More</button>
                <img
                  src={forward1}
                  alt="forward"
                  style={{ width: '24px', height: '24px' }}
                />
              </span>
            )}
          </div>
        </div>
      </div>
      <div className={style['recent-post-container']}>
        <h1>Recent posts</h1>
        <div className={style['blogcard-container']}>
          {currentPosts.map((post) => (
            <BlogCard
              key={post.id}
              title={post.title}
              content={post.content}
              image={post.image}
              post={post}
            />
          ))}
        </div>
        <div className={style['pagination']}>
          <button onClick={prevPage}>
            <img
              src={backward}
              alt="backward"
              style={{ width: '24px', height: '24px' }}
            />
          </button>
          {Array.from({ length: Math.ceil(posts.length / itemsPerPage) }).map(
            (_, index) => (
              <button
                key={index}
                onClick={() => paginate(index + 1)}
                className={style[currentPage === index + 1 ? 'active' : '']}
              >
                {index + 1}
              </button>
            ),
          )}
          <button onClick={nextPage}>
            <img
              src={forward}
              alt="forward"
              style={{ width: '24px', height: '24px' }}
            />
          </button>
        </div>
      </div>
      <Cta />
      <Footer />
    </div>
  );
};

export default BlogPage;
