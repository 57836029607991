import React, { useEffect, useState } from 'react';
import style from './Testimonial.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import avatarIImg from '../../libs/assets/images/1.jpg';
import avatarIIImg from '../../libs/assets/images/2.jpg';
import avatarIIIImg from '../../libs/assets/images/3.jpg';
import avatarIVImg from '../../libs/assets/images/4.jpg';
import avatarVImg from '../../libs/assets/images/5.jpg';

import 'swiper/swiper.scss';
import 'swiper/components/effect-coverflow/effect-coverflow.scss';
import 'swiper/components/pagination/pagination.scss';

import { EffectCoverflow, Navigation, Pagination } from 'swiper';

SwiperCore.use([Autoplay, EffectCoverflow, Pagination, Navigation]);

const Testimonial = () => {
  return (
    <div className={style['testimonial-container']}>
      <div className={style['testimonial-header']}>
        <h1>Testimonial</h1>
        <p>
          Witnessing Transformation: Real Stories of Financial Triumph with
          Valubah
        </p>
      </div>
      <Swiper
        loop={true}
        loopFillGroupWithBlank={true}
        // pagination={{
        //   clickable: true,

        // }}
        autoplay={{ delay: 3000 }}
        navigation={true}
        className={style['mySwiper']}
        effect={'coverflow'}
        coverflowEffect={{
          rotate: 0,
          stretch: 30,
          depth: 300,
          modifier: 1,
          slideShadows: false,
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 150,
          },
        }}
      >
        <SwiperSlide className={style['swiper-slide']}>
          <div
            style={{
              paddingRight: 20,
              paddingLeft: 20,
            }}
          >
            <div className={style['testimonials-profile-circle']}>
              <img
                src={avatarIImg}
                alt="testimonial-avatar"
                className={style['testimonial-avatar']}
              />
            </div>
            <p style={{ fontFamily: 'Raleway', textAlign: 'justify' }}>
              Valubah's Premium plan is a game-changer! For just ₦2,000/month, I
              get extensive health coverage, access to 800+ pharmacies, and
              telemedicine. The Micro Pension feature secures my future
              effortlessly. With Valubah, financial confidence is truly
              achievable.
            </p>
            <h6 className={style['review-by']}>- Gabriel Lawrence</h6>
          </div>
        </SwiperSlide>

        <SwiperSlide className={style['swiper-slide']}>
          <div
            style={{
              paddingRight: 20,
              paddingLeft: 20,
            }}
          >
            <div className={style['testimonials-profile-circle']}>
              <img
                src={avatarIIImg}
                alt="testimonial-avatar"
                className={style['testimonial-avatar']}
                loading="lazy"
              />
            </div>
            <p style={{ fontFamily: 'Raleway', textAlign: 'justify' }}>
              Valubah exceeded my expectations. The Premium plan's comprehensive
              health coverage and Micro Pension feature simplify my life. The
              Life Insurance and Personal Accident Cover offer unmatched
              protection. Valubah is my go-to for financial confidence and peace
              of mind.
            </p>
            <h6 className={style['review-by']}>- David Adeniyi</h6>
          </div>
        </SwiperSlide>

        <SwiperSlide className={style['swiper-slide']}>
          <div
            style={{
              paddingRight: 20,
              paddingLeft: 20,
            }}
          >
            <div className={style['testimonials-profile-circle']}>
              <img
                src={avatarIIIImg}
                alt="testimonial-avatar"
                className={style['testimonial-avatar']}
              />
            </div>
            <p style={{ fontFamily: 'Raleway', textAlign: 'justify' }}>
              Choosing Valubah was transformative. The Life Insurance and
              Personal Accident Cover provide peace of mind. The flexibility and
              generous benefits make it a reliable choice. Valubah is more than
              insurance; it's a holistic solution for a secure and empowered
              tomorrow.
            </p>
            <h6 className={style['review-by']}>- Oluwaseun Oyewole</h6>
          </div>
        </SwiperSlide>

        <SwiperSlide className={style['swiper-slide']}>
          <div
            style={{
              paddingRight: 20,

              paddingLeft: 20,
            }}
          >
            <div className={style['testimonials-profile-circle']}>
              <img
                src={avatarIVImg}
                alt="testimonial-avatar"
                className={style['testimonial-avatar']}
              />
            </div>
            <p style={{ fontFamily: 'Raleway', textAlign: 'justify' }}>
              Valubah Gold is my financial safeguard. For just ₦2,500/month, I
              enjoy access to 1000+ hospitals, covering everything from everyday
              illnesses to surgeries. The Micro Pension integration is seamless,
              and the Life Insurance and Personal Accident Cover provide
              comprehensive protection. Valubah Gold is my key to a secure and
              worry-free future.
            </p>
            <h6 className={style['review-by']}>- Mercy Sunday</h6>
          </div>
        </SwiperSlide>

        <SwiperSlide className={style['swiper-slide']}>
          <div
            style={{
              paddingRight: 20,
              paddingLeft: 20,
            }}
          >
            <div className={style['testimonials-profile-circle']}>
              <img
                src={avatarVImg}
                alt="testimonial-avatar"
                className={style['testimonial-avatar']}
              />
            </div>
            <p style={{ fontFamily: 'Raleway', textAlign: 'justify' }}>
              Valubah Gold's Hospital Plan is a game-changer. The coverage for
              delivery, surgeries, and everyday illnesses ensures complete peace
              of mind. The digital HMO ID is a convenient touch. With Valubah
              Gold, I feel secure, knowing that my health and financial
              well-being are in good hands.
            </p>
            <h6 className={style['review-by']}>- Moses Afolabi. </h6>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Testimonial;
