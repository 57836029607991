import React from 'react';
import { FaqCard } from './Faq-Card';
import style from './Faq.module.scss';
import Forward from '../libs/assets/images/forward.png';

const Faq = () => {
  const faqData = [
    {
      title: 'What exactly is Valubah?',
      content:
        'Valubah is a bouquet financial wellness service that has been set up to provide a financially secure future as well as take care of the present day or members of Nigeria’s in normal sector. Valubah has key benefits like Health Insurance, Life Insurance, Micro Pensions remittance and Accident Cover bundled together for as little as ₦2,000 monthly.',
    },
    {
      title: 'What does the HMO cover?',
      content:
        'Insurance coverage here strictly depends on the particular plan to which a subscriber/enrollee is registered. Currently, we have two plans in play; the Basic Plan and the Hospicash Plan.',
    },
    {
      title: 'How many times can I access my HMO in a month?',
      content:
        'It can access as many times as possible, but you can’t treat same sickness twice in a month ',
    },
    {
      title: 'What does life insurance covers?',
      content:
        'It covers Death, Accident, and Permanent Disability in the basic plan for Death benefit (100,000), accident cover (50,000), and permanent disability (30,000). Note: PLAN CAN BE ADJUSTED TO CLIENT REQUEST (FOR A HIGHER PLAN).',
    },
    {
      title: 'What does the (Accident cover) covers?',
      content:
        'It covers full treatment for accident victims and also help in covering their hospital bills in case of any surgery',
    },
    {
      title:
        'Is it possible for additional payments to be made into the pension?',
      content:
        'The Pensions savings under the plan is specifically for Micro-Pensions (Non mandatory). Same system, but a tier 2 Pensions setup. We also cover for mandatory and if the company already has a working system, we can easily factor this in and add the insurance cover also.',
    },
    {
      title: 'What if I want a higher plan for my HMO?',
      content:
        'Yes, a better plan that is not the basic HMO is possible and can be factor into the ESS plans',
    },
    {
      title: 'Is the Valubah plans location based?',
      content:
        'No is not, it can be access from whichever location the customer/staff is',
    },
    {
      title: 'What happens when a staff leaves an organization?',
      content:
        'The operations department is notified and the staff is removed from the plan, when the company has a new staff, the removed staff can now be replaced with a new staff.',
    },
  ];

  return (
    <div className={style['faq-home-conatiner']}>
      <h1 className={style['faq-title']}>Frequently Asked Questions</h1>
      <p className={style['faq-message']}>
        Unlock Financial Success with Valubah's Tailored Services
      </p>
      <div className={style['faq-list']}>
        {faqData.map((faq, index) => (
          <FaqCard key={index} title={faq.title} content={faq.content} />
        ))}
      </div>
      {/* <div className={style['faq-last-container']}>
        <p className={style['faq-last-content']}>
          See all frequently asked questions
        </p>{' '}
        <div>
          <img src={Forward} alt="forward" style={{ padding: '' }} />
        </div>
      </div> */}
    </div>
  );
};

export default Faq;
