import React from 'react';
import style from './Benefits.module.scss';
import img from '../../libs/assets/images/img.png';
import img1 from '../../libs/assets/images/img (1).png';
import img2 from '../../libs/assets/images/img (2).png';
import vector from '../../libs/assets/images/Vector.png';

const Benefits = () => {
  return (
    <div className={style['benefits-container']}>
      <div className={style['benefits-header']}>
        <h1 className={style['benefits-h1']}>
          Empowering Your Financial Journey
        </h1>
        <p className={style['benefits-text']}>
          Discover Valubah's Comprehensive Range of Financial Solutions
        </p>
      </div>

      <div className={style['benefits-card']}>
        <div className={style['benefits-card1']}>
          <img src={img} alt="vector" />
          <h3>Safeguarding Your Financial Future</h3>
          <p>
            Discover comprehensive protection packages, from health insurance to
            pension plans
          </p>
        </div>
        <div className={style['benefits-card1']}>
          <img src={img1} alt="" />
          <h3>Find Peace of Mind Through Security</h3>
          <p>
            Experience fortified financial well-being with meticulously crafted
            offerings.
          </p>
        </div>
        <div className={style['benefits-card1']}>
          <img src={img2} alt="" />
          <h3>Customized Financial Tools</h3>
          <p>
            Tailored to gig economy, informal sector needs, Valubah offers
            versatile financial solutions.
          </p>
        </div>

        <img src={vector} alt="" className={style['vector']} />
      </div>
    </div>
  );
};

export default Benefits;
