const ESS_URL = '/valubah';
const ROUTES = {
  ESS_SIGNUP: `${ESS_URL}/sign-up`,
  EXTERNAL_ESS_SIGN_IN: 'https://ess.awabahng.com/sign-in',
  ABOUT: '/about',
  EXTERNAL_LOGIN: 'https://dashboard.awabahng.com/sign-in',
  EXTERNAL_REGISTER: 'https://dashboard.awabahng.com/sign-up',
  FAQ: '/faq',
  PRODUCTS: '/products',
  PRIVACY: '/privacy',
  HOME: '/',
  BLOG: '/blog',
};
export default ROUTES;
