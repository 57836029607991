import React from 'react';
import GeneralNavBar from '../../layouts/GeneralNavBar';
import Footer from '../../layouts/Footer';
import Cta from '../home/Cta';
import Partners from '../home/Partners';
import style from './About.module.scss';
import Vector from '../../libs/assets/images/About-Vector.png';
import image1 from '../../libs/assets/images/aboutus-content-image.png';
import image2 from '../../libs/assets/images/woman-atm.png';
import tina from '../../libs/assets/images/tina.png';
import yemisi from '../../libs/assets/images/yemisi.png';
import tunji from '../../libs/assets/images/tunji.png';
import linkedin from '../../libs/assets/images/linkedinwhite.png';

const About = () => {
  return (
    <div className="">
      <div className={style['about-us-hero']}>
        <GeneralNavBar />
        <div className={style['about-us-header']}>
          <h1>Building Wealth Together</h1>
          <p>
            Enabling access to financial security for the unbanked and
            underbanked
          </p>
        </div>
      </div>
      <img src={Vector} alt="Vector" className={style['about-us-vector']} />
      <div className={style['about-us-content-contanier']}>
        <div className={style['about-us-content-header']}>
          <h1>Who Are We?</h1>
          <p>
            <span>A</span>wabah’s core mission is to help people outside of the
            regular 9-5 work model plan towards a financially safe and secure
            future. Set up in 2021, we have gone on to ensure that well over
            50,000 in normal sector workers have access to financial wellness
            services. We believe access to financial services should be
            available to every Nigerian. This is why we work to make micro
            pension services, as well as other financial wellness services
            available to any and every Nigerian.
          </p>
        </div>
        <div className={style['about-us-first-div']}>
          <h1>Awabah Product Offerings</h1>
          <p>Unlock Financial Success with Valubah's Tailored Services</p>
        </div>
        <div className={style['about-us-card']}>
          <div className={style['about-us-card1']}>
            <img src={image1} alt="image1" />
            <div className={style['about-us-inner-card']}>
              <h1>Valubah</h1>
              <p>
                Valubah is a bouquet financial wellness service that has been
                set up to provide a financially secure future as well as take
                care of the present day or members of Nigeria’s in normal
                sector. Valubah has key benefits like Health Insurance, Life
                Insurance, Micro Pensions remittance and Accident Cover bundled
                together for as little as ₦2,000 monthly. There are different
                Valubah packages and these benefits can also be curated to a
                user’s demands.
              </p>
              <button className={style['about-us-btn']}>Get started</button>
            </div>
          </div>
          <div className={style['about-us-card1']}>
            <div className={style['about-us-inner-card']}>
              <h1>PenPay</h1>
              <p>
                PenPay is a one stop digital tool for all forms of pension
                remittance. It is a payroll plugin solution dedicated to
                pensions payments and annuity customers. With PenPay, pension
                remittance is made in real time with no hassles and no delays.
                It is currently accessible via its website, a USSD code, as well
                as its app that can be downloaded on both Google Play Store and
                Apple Store.
              </p>
              <button className={style['about-us-btn1']}>
                <a href="https://www.penpay.com.ng/">See Website</a>
              </button>
            </div>

            <img src={image2} alt="image2" />
          </div>
        </div>
        <div className={style['about-us-executive']}>
          <h1>Our Executive Team</h1>
          <p>
            Behind Awabah's success is a dedicated team of professionals who
            bring expertise in finance, insurance, and retirement planning. Get
            to know the people who are passionate about your financial future
          </p>
        </div>
        <div className={style['about-us-team']}>
          <div className={style['about-us-team-card']}>
            <img src={tunji} alt="tunji" />
            <div className={style['about-us-team-name']}>
              <h1>Tunji Andrew</h1>
              <div className={style['about-us-circle']}>
                <a href="https://www.linkedin.com/in/tunji-andrews-6182a130/">
                  {' '}
                  <img src={linkedin} alt="linkedin" />
                </a>
              </div>
            </div>
            <p>Co-Founder</p>
            <p>tunji@awabahng.com</p>
          </div>
          <div className={style['about-us-team-card']}>
            <img src={yemisi} alt="yemisi" />
            <div className={style['about-us-team-name']}>
              <h1>Yemisi Obitayo</h1>
              <div className={style['about-us-circle']}>
                <a href="https://www.linkedin.com/in/oluwayemisi-obitayo-958009b8/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app">
                  <img src={linkedin} alt="linkedin" />
                </a>
              </div>
            </div>
            <p>VP, Business Growth, Operations and Partnership Management</p>
            <p>yemisi@awabahng.com</p>
          </div>
          <div className={style['about-us-team-card']}>
            <img src={tina} alt="tina" />
            <div className={style['about-us-team-name']}>
              <h1>Oluwabukola Ajishebiyawo</h1>
              <div className={style['about-us-circle']}>
                <a href="https://www.linkedin.com/in/oluwabukolatina?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app">
                  <img src={linkedin} alt="linkedin" />
                </a>
              </div>
            </div>
            <p>Chief Technology Officer</p>
            <p>tina@awabahng.com</p>
          </div>
        </div>
      </div>
      <Partners />
      <Cta />
      <Footer />
    </div>
  );
};

export default About;
