import React, { useEffect, useState } from 'react';
import Ornament from '../../../libs/assets/images/Ornament1.png';
import Ornament1 from '../../../libs/assets/images/Ornament2.png';
import { Icon } from '@iconify/react';
import style from './Retirement.module.scss';

const RetirementCalculator = () => {
  const [selectedOption, setSelectedOption] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOptionYear, setSelectedOptionYear] = useState('');
  const [isDropdownOpenYear, setIsDropdownOpenYear] = useState(false);
  const [paymentFrequency, setPaymentFrequency] = useState('Monthly');
  const [amount, setAmount] = useState(0);
  const [pensionTotal, setPensionTotal] = useState(0);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSelect = (value) => {
    setPaymentFrequency(value);
    setSelectedOption(value);
    setIsDropdownOpen(false);
  };

  const toggleDropdownYear = () => {
    setIsDropdownOpenYear(!isDropdownOpenYear);
  };

  const handleSelectYear = (value) => {
    setSelectedOptionYear(value);
    setIsDropdownOpenYear(false);
  };

  const years = [];
  for (let year = 2023; year <= 2100; year++) {
    years.push(year);
  }

  useEffect(() => {
    const calculatePension = () => {
      const rate = 2;
      const year = parseInt(selectedOptionYear);
      const nInMonths = 12;

      let paymentFrequencyFactor = 1;

      if (paymentFrequency === 'weekly') {
        paymentFrequencyFactor = nInMonths / 52;
      } else if (paymentFrequency === 'daily') {
        paymentFrequencyFactor = nInMonths / 365;
      }

      const monthlyInterestRate = rate / 100 / nInMonths;
      const monthlyContributions = amount;

      const A =
        (monthlyContributions *
          (Math.pow(1 + monthlyInterestRate, nInMonths * year) - 1)) /
        (monthlyInterestRate *
          Math.pow(1 + monthlyInterestRate, nInMonths * year));

      setPensionTotal(A);
    };

    calculatePension();
  }, [amount, selectedOptionYear, paymentFrequency]);

  return (
    <div className={style['retirement-container']}>
      <img
        src={Ornament}
        alt="ornament"
        style={{ width: '350px', height: '400px' }}
        className={style['ornament']}
      />

      <div className={style['retirement-calculator-container']}>
        <div className={style['retirement-header-container']}>
          <h3>Plan Your Tomorrow with Confidence</h3>
          <p>How much will you have at retirement?</p>
        </div>

        <div className={style['retirement-contribution-container']}>
          <p>Contributions</p>

          <input
            aria-label="monthly-payment-input"
            name="monthlyPayment"
            value={amount || ''}
            onChange={(e) => setAmount(parseFloat(e.target.value))}
            type="number"
            className={style['landing_input']}
            placeholder=" ₦20,000"
          />
        </div>
        <div className={style['dropdown-container']}>
          <div className={style['dropdown-container-monthly']}>
            <div
              className={style['dropdown-button-monthly']}
              onClick={toggleDropdown}
            >
              {selectedOption ? selectedOption : 'Monthly'}
              <Icon icon={'fa:angle-down'} />
            </div>
            {isDropdownOpen && (
              <ul className={style['dropdown-options-monthly']}>
                <li onClick={() => handleSelect('Daily')}>Daily</li>
                <li onClick={() => handleSelect('Weekly')}>Weekly</li>
                <li onClick={() => handleSelect('Monthly')}>Monthly</li>
              </ul>
            )}
          </div>

          <div className={style['dropdown-container-year']}>
            <div
              className={style['dropdown-button-year']}
              onClick={toggleDropdownYear}
            >
              {selectedOptionYear
                ? selectedOptionYear
                : 'Expected Year of Retirement'}
              <Icon icon={'fa:angle-down'} />
            </div>
            {isDropdownOpenYear && (
              <ul className={style['dropdown-options-year']}>
                {years.map((year) => (
                  <li
                    key={year}
                    onClick={() => handleSelectYear(year.toString())}
                  >
                    {year}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        <div className={style['retirement-amount-container']}>
          <p>Estimated Retirement Amount</p>
          <h1> ₦ {pensionTotal ? pensionTotal.toFixed(2) : '340,050,000'} </h1>
        </div>

        <p className={style['retirement-get-started-btn']}>
          <a href="/signup" style={{ textDecoration: 'none' }}>
            {' '}
            Get Started
          </a>
        </p>
      </div>
      <div className={style['last-ornament']}>
        <img src={Ornament1} alt="" />
      </div>
    </div>
  );
};

export default RetirementCalculator;
