import React from 'react';
import '../main.scss';
import { Switch, Route } from 'react-router-dom';
import Home from '../components/home/Home';
import Products from '../components/product/Products';
import Privacy from '../components/privacy/Privacy';
import About from '../components/about/About';
import ROUTES from './url';
import Faq from '../components/Faq-page';
import EssSignUp from '../components/sign-up/EssSignUp';
import Blog from '../components/blog/BlobPage';
import FullBlogPost from '../components/blog/FullBlogPost';

const App = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/product/:productType" component={Products} />
      <Route exact path={ROUTES.BLOG} component={Blog} />
      <Route path="/blog/:postId" component={FullBlogPost} />
      <Route exact path={ROUTES.PRIVACY} component={Privacy} />
      <Route exact path={ROUTES.ABOUT} component={About} />
      <Route exact path={ROUTES.FAQ} component={Faq} />
      <Route exact path="/signup" component={EssSignUp} />
    </Switch>
  );
};

export default App;
