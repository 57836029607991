import React from 'react';
import style from './Hero.module.scss';
import GeneralNavBar from '../../layouts/GeneralNavBar';

const Hero = () => {
  return (
    <div>
      {window.innerWidth <= 600 ? (
        <div className={style['hero-image']}>
          <GeneralNavBar />
          <div className={style['background-text-container']}>
            <h1 className={style['background-head']}>
              <span className={style['background-span']}>Valubah:</span> Your
              Path to Financial Balance and Security
            </h1>
            <p className={style['background-text']}>
              Explore our comprehensive and affordable offerings for informal
              earners, gig workers, and MSMEs.
            </p>
            <p className={style['btn-login']}>
              {' '}
              <a href="/signup" style={{ textDecoration: 'none' }}>
                {' '}
                Get Started
              </a>
            </p>
          </div>
        </div>
      ) : (
        <div className={style['hero-image']}>
          <GeneralNavBar />
          <div className={style['background-text-container']}>
            <h1 className={style['background-head']}>
              <span className={style['background-span']}>Valubah:</span> Your
              Path to Financial Balance and Security
            </h1>
            <p className={style['background-text']}>
              Explore our comprehensive and affordable offerings for informal
              earners, gig workers, and MSMEs.
            </p>
            <p className={style['btn-login']}>
              {' '}
              <a href="/signup" style={{ textDecoration: 'none' }}>
                {' '}
                Get Started
              </a>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Hero;
