import React from 'react';
import GeneralNavBar from '../../layouts/GeneralNavBar';
import Footer from '../../layouts/Footer';
import Cta from '../home/Cta';
import Logo from '../../libs/assets/logo2_3.png';
import { useLocation } from 'react-router-dom';
import style from './Blog.module.scss';

type BlogPostData = {
  title: string;
  content: string;
  image: string;
};

const FullBlogPost: React.FC = () => {
  const location = useLocation();
  const {
    title = '',
    content = '',
    image = '',
  } = (location.state as BlogPostData) || {};

  return (
    <div>
      <GeneralNavBar linkColor={'black'} logoImage={Logo} />
      <div className={style['full-blog-page-container']}>
        <h1>{title}</h1>
        <img src={image} alt={title} />
        <p>{content}</p>
      </div>

      <Cta />
      <Footer />
    </div>
  );
};

export default FullBlogPost;
