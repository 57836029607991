import React from 'react';
import plus from '../libs/assets/images/plus.png';
import minus from '../libs/assets/images/Group.png';
import style from './Faq.module.scss';

export const FaqCard = ({ title, content }) => {
  const [expanded, setExpanded] = React.useState(false);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={style['faq-card-container']}>
      <div
        className={`${style['faq-header']} ${
          expanded ? style['expanded'] : ''
        }`}
      >
        <div className={style['faq-tag']}>
          <p className={style['faq-card-title']}>{title}</p>
          <p onClick={toggleExpansion}>
            {expanded ? (
              <img src={minus} alt="Collapse" />
            ) : (
              <img src={plus} alt="Expand" />
            )}
          </p>
        </div>
        {expanded && (
          <div className={style['faq-content']}>
            <p className={style['faq-card-content']}>{content}</p>
          </div>
        )}
      </div>
    </div>
  );
};
