import React from 'react';
import style from './Partner.module.scss';
import Leadway from '../../libs/assets/partner/leadway.png';
import Stanbic from '../../libs/assets/partner/stanbic IBTC.png';
import Access from '../../libs/assets/partner/access.png';
import Arm from '../../libs/assets/partner/ARM.png';
import tangeria from '../../libs/assets/partner/Tangerine.png';
import penop from '../../libs/assets/partner/Partners Logos.png';
import pal from '../../libs/assets/partner/pal.png';
import fedility from '../../libs/assets/partner/Fidelity.png';
import vertais from '../../libs/assets/partner/veritas.png';
import Fcmb from '../../libs/assets/partner/FCMB.png';
import wella from '../../libs/assets/partner/wellahealth.png';
import covergen from '../../libs/assets/partner/mycovergenius.png';
import consolidate from '../../libs/assets/partner/consolidated.png';

const Partners = () => {
  return (
    <div className={style['partner-container']}>
      <h1 className={style['partner-container-header']}>Our Partners</h1>
      <div className={style['partner-logo-container']}>
        <img src={Leadway} alt="Leadway" />
        <img src={Stanbic} alt="Stanbic" />
        <img src={Access} alt="Access" />
        <img src={Arm} alt="Arm" />
        <img src={tangeria} alt="tangeria" />
        <img src={penop} alt="penop" />
        <img src={pal} alt="pal" />
        <img src={fedility} alt="fedility" />
        <img src={vertais} alt="vertais" />
        <img src={Fcmb} alt="Fcmb" />
        <img src={wella} alt="wella" />
        <img src={covergen} alt="covergen" />
        <img src={consolidate} alt="consolidate" />
      </div>
    </div>
  );
};

export default Partners;
