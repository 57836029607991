import React from 'react';
import style from './Coverage.module.scss';
import img1 from '../../libs/assets/images/Recta.png';
import img2 from '../../libs/assets/images/black.png';
import img3 from '../../libs/assets/images/parents.png';
import img4 from '../../libs/assets/images/Rect.png';

const Coverage = () => {
  return (
    <div className={style['coverage-container']}>
      <div className={style['coverage-header']}>
        <h1>Essential Coverage for Your Peace of Mind</h1>
        <p>Valubah's Solutions: Secure Today, Protect Tomorrow</p>
      </div>
      {window.innerWidth <= 600 ? (
        <div className={style['mobile-container']}>
          <div className={style['mobile-container-content']}>
            <img src={img1} alt="img1" />
            <h3>MICRO-PENSION</h3>
            <h1>Secure Your Tomorrow</h1>
            <p>
              Today Valubah's Micro Pensions empower you to build a robust
              financial future, no matter your income level. Start small, save
              smart, and ensure a dignified retirement journey with our tailored
              pension plans.
            </p>
          </div>
          <div className={style['mobile-container-content']}>
            <img src={img2} alt="img2" /> <h3>HEALTH INSURANCE</h3>
            <h1>Protecting Your Well-Being </h1>
            <p>
              Valubah's Health Insurance provides comprehensive coverage,
              ensuring you're safeguarded against unexpected medical expenses.
              Focus on your health and peace of mind, knowing Valubah has you
              covered.
            </p>
          </div>
          <div className={style['mobile-container-content']}>
            <img src={img3} alt="img3" />
            <h3>LIFE INSURANCE</h3>
            <h1>Securing Legacies</h1>
            <p>
              Creating Peace With Valubah's Life Insurance, you're securing your
              loved ones' future, even in your absence. Leave behind a legacy of
              financial stability and ensure their peace of mind.
            </p>
          </div>
          <div className={style['mobile-container-content']}>
            <img src={img4} alt="img4" />
            <h3>ACCIDENT COVER</h3>
            <h1>Safety Beyond the Unexpected </h1>
            <p>
              Valubah's Accident Cover shields you from life's unexpected
              events. Whether it's a minor mishap or a major accident, our
              coverage ensures you're protected and supported.
            </p>
          </div>
        </div>
      ) : (
        <div className={style['background-container']}></div>
      )}
    </div>
  );
};

export default Coverage;
