import React from 'react';
import Hero from './Hero';
import Benefits from './Benefits';
import Coverage from './Coverage';
import Partners from './Partners';
import Footer from '../../layouts/Footer';
import Cta from './Cta';
import Faq from '../Faq';
import RetirementCalculator from './retirement-calculator/RetirementCalculator';
import Testimonial from './Testimonial';

const Home = () => {
  return (
    <div>
      <Hero />
      <Benefits />
      <Coverage />
      <RetirementCalculator />
      <Testimonial />
      <Faq />
      <Cta />
      <Partners />
      <Footer />
    </div>
  );
};

export default Home;
