const apiBaseUrl =
  process.env.NODE_ENV === 'development'
    ? 'https://api-staging-customer.awabahng.com/api/v1/awabah'
    : 'https://api-customer.awabahng.com/api/v1/awabah';
const essApiBaseUrl =
  process.env.NODE_ENV === 'development'
    ? 'https://api-ess-staging.awabahng.com/api/v1/ess/'
    : 'https://api-ess.awabahng.com/api/v1/ess/';
export const AppConstants = {
  API_BASEURL: apiBaseUrl,
  ESS_API_BASEURL: essApiBaseUrl,
  AUTH_TOKEN: 'session',
  Validation: {
    ALL_DIGIT: '^[0-9]+$',
    ACCOUNT_NUMBER: '^[0-9]{10}$',
    NAME_REGEX: '^[A-Za-z]+$',
    PASSWORD_REGEX: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\W|_]).{8,20}$',
    PHONE_REGEX: '^[0-9]{10}$',
    PHONE_NUMBER_REGEX: '^[0-9]{11}$',
    EMAIL_REGEX: '\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,})',
    BVN_REGEX: '^[0-9]{11}$',
    RSA_PIN_REGEX: '^PEN[0-9]{12}$',
    PASSWORD_CHECKS: [
      {
        id: 1,
        title: 'at least one lowercase letter',
        pattern: '^(?=.*[a-z])',
      },
      {
        id: 2,
        title: 'at least one uppercase letter',
        pattern: '^(?=.*[A-Z])',
      },
      {
        id: 3,
        title: 'at least one number',
        pattern: '^(?=.*[0-9])',
      },
      {
        id: 4,
        title: 'at least one special character',
        pattern: '^(?=.*[\\W|_])',
      },
    ],
    MINIMUM_AMOUNT: `^[0-9]{3,}$`,
    LOGIN_PHONE_REGEX: '^[0-9]{11}$',
  },
};
