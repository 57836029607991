import React from 'react';
import Footer from '../layouts/Footer';
import GeneralNavBar from '../layouts/GeneralNavBar';
import Logo from '../libs/assets/logo2_3.png';
import style from './Faq.module.scss';
import Cta from '../components/home/Cta';
import Faq from './Faq';

const Faqpage = () => {
  return (
    <div>
      <GeneralNavBar linkColor={'black'} logoImage={Logo} />
      <div className={style['faq-main-page-header']}>
        <h1>All The Help You Need In One Place</h1>
        <p>Quick answers to questions you might have</p>
      </div>
      <div>
        <Faq />
      </div>
      <Cta />
      <Footer />
    </div>
  );
};

export default Faqpage;
