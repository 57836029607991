import React from 'react';
import Ring from '../../libs/assets/images/BACKGROUND 1.png';
import style from './Cta.module.scss';

const PlanCta = () => {
  return (
    <div className={style['cta-container']}>
      <img src={Ring} alt="" className={style['cta-img']} />
      <div className={style['cta-content-container']}>
        <p className={style['cta-header']}>Start Your Journey</p>
        <h1 className={style['cta-h1']}>
          Empower your financial future with Valubah's comprehensive solutions.
        </h1>
        <p className={style['btn-cta']}>
          <a href="signup" style={{ textDecoration: 'none' }}>
            Get Started
          </a>
        </p>
      </div>
    </div>
  );
};

export default PlanCta;
