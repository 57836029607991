import { AppConstants } from '../../utils/index';
import axios from 'axios';

export function getWebAccessToken() {
  return localStorage.getItem(AppConstants.AUTH_TOKEN);
}

async function getToken() {
  return getWebAccessToken();
}

function getAuthorizationHeader(token: string | null) {
  return { Authorization: token };
}

function getHttpClient() {
  return axios.create({
    baseURL: AppConstants.API_BASEURL,
    // headers: getAuthorizationHeader(token) as any,
  });
}
function getESSHttpClient(token: string | null) {
  return axios.create({
    baseURL: AppConstants.ESS_API_BASEURL,
    headers: getAuthorizationHeader(token) as any,
  });
}
interface HttpClientParams {
  path: string;
  method?: 'post' | 'get' | 'put' | 'patch' | 'delete';
  data?: Record<string, any>;
  queryParams?: Record<string, any>;
}

export const HttpClient = async (params: HttpClientParams) => {
  let result = undefined;
  let error: Error | undefined = undefined;
  try {
    const response = await getHttpClient()[params.method || 'get'](
      params.path,
      ['get', 'delete'].includes(params.method || 'get')
        ? { params: params.queryParams || {} }
        : params.data,
    );
    result = response.data;
  } catch (err: any) {
    error = new Error(err.response?.data?.message || err.message);
  }
  return { result, error };
};
export const ESSHttpClient = async (params: HttpClientParams) => {
  const token = await getToken();
  let result = undefined;
  let error: Error | undefined = undefined;
  try {
    const response = await getESSHttpClient(token)[params.method || 'get'](
      params.path,
      ['get', 'delete'].includes(params.method || 'get')
        ? { params: params.queryParams || {} }
        : params.data,
    );
    result = response.data;
  } catch (err: any) {
    error = new Error(err.response?.data?.message || err.message);
  }
  return { result, error };
};
