import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AwabahLogo from '../libs/assets/images/awabahLogo.svg';
import Logo from '../libs/assets/logo2_3.png';
import style from './GeneralNavBar.module.scss';
import ProductModal from './modal/ModalLayout';
import hambuger from '../libs/assets/images/hamburger.svg';
import close from '../libs/assets/images/close.svg';
import { LOGIN_URL } from '../libs/api/base/base-url';

const GeneralNavBar = ({ logoImage, linkColor, logoSize }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showMobileLogo, setShowMobileLogo] = useState(window.innerWidth < 600);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const openModal = (event) => {
    event.preventDefault();
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleProductSelection = (product) => {
    setSelectedProduct(product);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    setShowMobileLogo(window.innerWidth < 600);
  };

  return (
    <div className={style['nav-container']}>
      <div className={style['logo-container']}>
        <a href="/" style={{ textDecoration: 'none' }}>
          <img
            src={showMobileLogo ? Logo : logoImage}
            alt="Awabah Logo"
            style={{ width: logoSize, height: 'auto' }}
            className="logo"
          />
        </a>
      </div>
      <img
        src={isMobileMenuOpen ? close : hambuger}
        alt="Toggle Menu"
        className={style['mobile-menu-toggle']}
        onClick={toggleMobileMenu}
      />
      <div className={style['list-container']}>
        <ul className={style['custom-list']}>
          <li>
            <a
              href=""
              style={{ textDecoration: 'none', color: linkColor }}
              onClick={openModal}
            >
              Products
            </a>
          </li>
          <li>
            <a
              href="/about"
              style={{ textDecoration: 'none', color: linkColor }}
            >
              About Us
            </a>
          </li>
          <li>
            <a
              href="/blog"
              style={{ textDecoration: 'none', color: linkColor }}
            >
              Blog
            </a>
          </li>
        </ul>
      </div>

      <div className={style['login-container']}>
        <a
          href={`${LOGIN_URL}`}
          className={style['login']}
          style={{ color: linkColor }}
        >
          Sign In
        </a>
        <Link to="/signup" className={style['get-started']}>
          Get Started
        </Link>
      </div>
      {isMobileMenuOpen ? (
        <div className={style['mobile-menu-content']}>
          <ul className={style['custom-list']}>
            <li>Products</li>
            <li>
              <Link
                to="/product/Valubah%20Premium"
                style={{ textDecoration: 'none', color: linkColor }}
              >
                Valubah Premium
              </Link>
            </li>
            <li>
              <Link
                to="/product/Valubah%20Gold"
                style={{ textDecoration: 'none', color: linkColor }}
              >
                Valubah Gold
              </Link>
            </li>
            <li>
              <a href="/about" style={{ textDecoration: 'none' }}>
                About Us
              </a>
            </li>
            <li>
              <a href="/blog" style={{ textDecoration: 'none' }}>
                Blog
              </a>
            </li>
          </ul>
          <div>
            <a
              href={`${LOGIN_URL}`}
              className={style['login']}
              style={{ color: linkColor }}
            >
              Sign In
            </a>
            <Link to="/signup" className={style['get-started']}>
              Get Started
            </Link>
          </div>
        </div>
      ) : null}

      {isModalOpen && (
        <ProductModal
          closeModal={closeModal}
          handleProductSelection={handleProductSelection}
        />
      )}
    </div>
  );
};

GeneralNavBar.defaultProps = {
  logoImage: AwabahLogo,
  linkColor: '#fff',
  logoSize: '100px',
};

export default GeneralNavBar;
