import { ESSHttpClient } from './http';
import { INDIVIDUAL_SIGN_UP_API } from './base-url';
import axios, { AxiosError } from 'axios';

interface FormDataModel {
  firstName: string;
  lastName: string;
  company: string;
  companyAddress: string;
  email: string;
  phoneNumber: string;
  password: string;
}

interface FormDataIndiviual {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  password: string;
}

export async function SubmitSignUp(body: FormDataModel) {
  return await ESSHttpClient({
    path: 'auth/register',
    data: body,
    method: 'post',
  });
}

export const signup = async (formData: FormDataIndiviual) => {
  try {
    const apiUrl = INDIVIDUAL_SIGN_UP_API + '/auth/register';
    const response = await axios.post(apiUrl, formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return { error: null, result: response.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      console.error('Signup error:', axiosError.message);
      return { error: { message: axiosError.message }, result: null };
    } else {
      console.error('Unexpected error during signup:', error);
      return { error: { message: 'Unexpected error occurred' }, result: null };
    }
  }
};
