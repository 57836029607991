import React from 'react';
import AwabahLogo from '../libs/assets/awabah_logo.svg';
import facebook from '../libs/assets/images/Facebook.png';
import twitter from '../libs/assets/images/X svg.png';
import instagram from '../libs/assets/images/Instagram.png';
import linkedin from '../libs/assets/images/LinkedIn.png';
import google from '../libs/assets/images/play-store-.png';
import apple from '../libs/assets/images/app-store.png';
import style from './Footer.module.scss';

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <div className={style['footer']}>
      <div className={style['footer-container']}>
        <div className={style['Logo-container']}>
          <img
            alt="Logo"
            src={AwabahLogo}
            width={'100%'}
            className={style['footer-logo-image']}
          />
          <div className={style['footer-icon-container']}>
            <a href="https://www.linkedin.com/company/awabah/">
              <img src={linkedin} alt="linkedin" />
            </a>
            <a href="https://www.instagram.com/awabahnigeria/">
              <img src={instagram} alt="instagram" />
            </a>
            <a href="https://twitter.com/AwabahNigeria">
              <img src={twitter} alt="twitter" />
            </a>
            <a href="https://web.facebook.com/AwabahNigeria">
              <img src={facebook} alt="facebook" />
            </a>
          </div>
        </div>

        <div className={style['footer-content1']}>
          <div className={style['footer-content-wrapper']}>
            <h1 className={style['footer-content-header']}>Products</h1>
            <p>Valubah</p>
            <button className={style['playstore']}>
              <a href="https://play.google.com/store/apps/details?id=com.awabah&pcampaignid=web_share">
                Click here to Download on Play store
              </a>
            </button>
            <button className={style['playstore']}>
              <a href="https://apps.apple.com/us/app/awabah/id6444802975'">
                Click here to Download on App store
              </a>
            </button>
            <a
              href="https://www.penpay.com.ng/"
              style={{ textDecoration: 'none' }}
            >
              Penpay
            </a>
          </div>
        </div>
        <div className={style['footer-content1']}>
          <div className={style['footer-content-wrapper']}>
            <h1 className={style['footer-content-header']}>Other Links</h1>

            <a href="/about" style={{ textDecoration: 'none' }}>
              About Us
            </a>
            <a href="/blog" style={{ textDecoration: 'none' }}>
              Blog
            </a>
            <a href="/faq" style={{ textDecoration: 'none' }}>
              Frequently Asked Questions
            </a>
            <a href="/privacy" style={{ textDecoration: 'none' }}>
              Privacy Policy
            </a>
            <p>Terms and Conditions</p>
          </div>
        </div>
        <div className={style['footer-content1']}>
          <div className={style['footer-content-wrapper']}>
            <h1 className={style['footer-content-header']}>Contact Us</h1>
            <a
              href="mailto:support@awabahng.com"
              style={{ textDecoration: 'none' }}
            >
              support@awabahng.com
            </a>
            <p>+234 122 967 33</p>
            <p style={{ width: '70%' }}>
              97, Adeola Odeku Street, Victoria Island, Lagos State
            </p>
          </div>
        </div>
      </div>
      <div className={style['footer-line-container']}>
        <hr className={style['footer-line']} />{' '}
        <p>&#169; Copyright Valubah {year}</p>
        <hr className={style['footer-line']} />
      </div>
    </div>
  );
};

export default Footer;
