import React, { useEffect, useRef } from 'react';
import style from './ProductModal.module.scss';
import { useHistory } from 'react-router-dom';

const ProductModal = ({ closeModal }) => {
  const history = useHistory();
  const modalRef = useRef<HTMLDivElement | null>(null);

  const handleProductSelection = (product) => {
    history.push(`/product/${product}`);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        closeModal();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [closeModal]);

  return (
    <div className={style['modal-container']}>
      <div ref={modalRef} className={style['modal-content']}>
        <ul className={style['product-list']}>
          <li onClick={() => handleProductSelection('Valubah Premium')}>
            Valubah Premium
          </li>
          <li onClick={() => handleProductSelection('Valubah Gold')}>
            Valubah Gold
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ProductModal;
