import React from 'react';
import GeneralNavBar from '../../layouts/GeneralNavBar';
import Logo from '../../libs/assets/logo2_3.png';
import granpa from '../../libs/assets/images/grandpa.jpg';
import womanCar from '../../libs/assets/images/inside-car.jpg';
import man from '../../libs/assets/images/man-sitting-car.jpg';
import icon from '../../libs/assets/images/product1icon.png';
import backIcon from '../../libs/assets/images/proback.png';
import check from '../../libs/assets/images/check-circle.png';
import style from './Product.module.scss';
import Faq from '../Faq';
import Cta from '../home/Cta';
import Footer from '../../layouts/Footer';
import { useParams } from 'react-router-dom';

interface RouteParams {
  productType: string;
}

const Products = () => {
  const { productType } = useParams<RouteParams>();

  const productContents = {
    'Valubah Premium': {
      title: 'Valubah Premium',
      price: '₦2,000/month',
      description: 'Health Insurance (Pharmacy Alone)',
      items: [
        'Access to over 800 pharmacy across Nigeria',
        'Malaria tests',
        'Malaria drugs',
        'Typhoid Tests',
        'Typhoid Drugs',
        'Heart check',
        'Diabetes Check',
        'Telemedicine',
      ],
    },
    'Valubah Gold': {
      title: 'Valubah Gold',
      price: '₦2,500/month',
      description: 'Health Insurance (Hospital Plan)',
      items: [
        'Access to over 1000 hospitals across Nigeria',
        'Cover for Delivery and Ante-natal Care',
        'Treatment of everyday illnesses, like malaria, e.t.c',
        'Cover for 26 different types of surgeries',
        'A digital HMO ID for each person',
      ],
    },
  };

  const content = productContents[productType];
  if (!content) {
    return null;
  }
  return (
    <div className={style['product-page-container']}>
      <GeneralNavBar linkColor={'#3D663D'} logoImage={Logo} />
      <div className={style['product-title-header']}>
        <a href="/" style={{ textDecoration: 'none' }}>
          Home
        </a>

        <span>/</span>
        <p>{content.title}</p>
      </div>
      <div className={style['product-big-icon']}>
        <img src={backIcon} alt="backIcon" />
      </div>
      <div className={style['product-main-container']}>
        <div className={style['products-header-content']}>
          <h1>Choose Your Path to Financial Confidence</h1>
          <p>
            Valubah offers flexible pricing plans tailored to suit your
            financial goals and needs. Whether you're an individual seeking
            comprehensive protection or a business aiming to empower your
            workforce, our pricing options ensure you can achieve a secure
            tomorrow
          </p>
        </div>

        <div className={style['product-dynamic-content']}>
          <div className={style['product-dynamic-header']}>
            <h1>{content.title}</h1>
            <h2>{content.price}</h2>
          </div>
          <div className={style['product-offer-container']}>
            <div className={style['product-offer-container1']}>
              <h1>{content.description}</h1>

              <div>
                {content.items.map((item, index) => (
                  <div key={index} className={style['special-product-content']}>
                    <img src={check} alt="check" />
                    <p>{item}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className={style['product-offer-container2']}>
              <h1>Pension(Micro pension)</h1>
              <div>
                <img src={check} alt="check" />
                <p>Register you for micro pension</p>
              </div>
              <div>
                <img src={check} alt="check" />
                <p>remit into your micro pension account</p>
              </div>
              <div>
                <img src={check} alt="check" />
                <p>
                  You can as well use penpay to remit into your micro pension
                  account.
                </p>
              </div>
            </div>
            <div className={style['product-offer-container3']}>
              <h1>Life Insurance</h1>
              <div>
                <img src={check} alt="check" />
                <p>Death benefit limit up to ₦150,000</p>
              </div>
              <div>
                <img src={check} alt="check" />
                <p>Accident permanent benefit up to ₦50,000</p>
              </div>
              <div>
                <img src={check} alt="check" />
                <p>Medical expenses limit up to ₦30,000</p>
              </div>
            </div>
            <div className={style['product-offer-container4']}>
              <h1>Personal Accident Cover</h1>
              <div>
                <img src={check} alt="check" />
                <p>
                  24 hours coverage worldwide with no Medical Examination
                  required
                </p>
              </div>
              <div>
                <img src={check} alt="check" />
                <p>Medical expenses limit up to ₦50,000</p>
              </div>
              <div>
                <img src={check} alt="check" />
                <p>Death benefit limit up to ₦250,000</p>
              </div>
              <div>
                <img src={check} alt="check" />
                <p>Permanent disability limit up to ₦500,000</p>
              </div>
            </div>
          </div>
          <div className={style['products-btn-div']}>
            <button>Get Started</button>
          </div>
        </div>
        <div className={style['products-middle-container']}>
          <div className={style['product-people-container']}>
            <img src={granpa} alt="grandpa" />
            <div className={style['product-persons-container']}>
              <img src={man} alt="man" />
              <img src={womanCar} alt="womanCar" />
            </div>
          </div>
          <div className={style['product-middle-content']}>
            <h1>Need a Customized Plan</h1>
            <p>
              For individuals and businesses with specific requirements, our
              custom plan offers a personalized combination of services. Get in
              touch with us to create a plan that aligns perfectly with your
              goals
            </p>
            <button>Contact Us</button>
          </div>
          <div className={style['down-icon']}>
            <img src={icon} alt="icon" />
          </div>
        </div>
      </div>

      <Faq />
      <Cta />
      <Footer />
    </div>
  );
};

export default Products;
