import React, { useState } from 'react';
import style from './Blog.module.scss';
import { useHistory } from 'react-router-dom';
import calendar from '../../libs/assets/images/calendar.png';
import clock from '../../libs/assets/images/clock.png';
import forward from '../../libs/assets/images/forward.png';

const BlogCard = ({ title, content, image, post }) => {
  const [showFullContent, setShowFullContent] = useState(false);

  const history = useHistory();

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  const redirectToFullBlog = () => {
    history.push({
      pathname: `/blog/${post.id}`,
      state: { title, content, image },
    });
  };

  return (
    <div className={style['blog-post-card']}>
      <img src={image} alt={title} />
      <span>
        <img
          src={calendar}
          alt="calendar"
          style={{ width: '24px', height: '24px' }}
        />{' '}
        11 Sep 2023
        <img
          src={clock}
          alt="time"
          style={{ width: '24px', height: '24px' }}
        />{' '}
        5 min read
      </span>
      <h2>{title}</h2>
      <p>{showFullContent ? content : content.substring(0, 100)}...</p>
      {content.length > 100 && !showFullContent && (
        <div className={style['read-more']}>
          <button onClick={redirectToFullBlog}>Read More</button>
          <img
            src={forward}
            alt="forward"
            style={{ width: '24px', height: '24px' }}
          />
        </div>
      )}
    </div>
  );
};

export default BlogCard;
