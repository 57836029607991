import React, { FormEvent, useEffect, useState } from 'react';
import style from './styles.module.scss';
import { toast } from 'react-toastify';
import { FieldChangePayload } from '../survey/EnterpriseSupportSolution';
import { SubmitSignUp } from '../../libs/api/base/sign-up';
import check from '../../libs/assets/images/check.png';
import arrow from '../../libs/assets/images/arrow.svg';
import gary from '../../libs/assets/images/Checkgray.svg';
import green from '../../libs/assets/images/CheckOutline.svg';
import eye from '../../libs/assets/images/trailing.png';
import briefcase from '../../libs/assets/images/briefcase-color.svg';
import users from '../../libs/assets/images/users.svg';
import Logo from '../../libs/assets/logo2_3.png';
import AwabahLogo from '../../libs/assets/images/awabahLogo.svg';
import { LOGIN_URL } from '../../libs/api/base/base-url';
import { signup } from '../../libs/api/base/sign-up';
export interface FormDataModel {
  firstName: string;
  lastName: string;
  company: string;
  companyAddress: string;
  email: string;
  phoneNumber: string;
  password: string;
}

interface IndividualFormDataModel {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  password: string;
}
const EssSignUp = () => {
  const [selectedType, setSelectedType] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const [step, setStep] = useState(1);
  const [formData, SetFormData] = useState<FormDataModel>({
    firstName: '',
    lastName: '',
    company: '',
    companyAddress: '',
    email: '',
    phoneNumber: '',
    password: '',
  });

  const [individualFormData, SetIndividualFormData] =
    useState<IndividualFormDataModel>({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      password: '',
    });

  const [submitting, SetSubmitting] = useState(false);
  const [titleLoading] = useState(false);
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (step === 3) {
      if (selectedType === 'individual') {
        individualSubmit(individualFormData);
      } else if (selectedType === 'business') {
        subMitForm(formData);
      }
    } else {
      setStep(step + 1);
    }
  };

  const individualSubmit = async (payload: IndividualFormDataModel) => {
    SetSubmitting(true);
    const { error, result } = await signup(payload);
    SetSubmitting(false);
    if (error) {
      toast(error.message, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: style['toast_body_error'],
      });
    } else {
      SetIndividualFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        password: '',
      });
      const rs: any = result;
      toast(rs.message, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: rs.status ? style['toast_body'] : style['toast_body_error'],
      });
      return;
    }
  };

  const subMitForm = async (payload: FormDataModel) => {
    SetSubmitting(true);
    const { error, result } = await SubmitSignUp(payload);
    SetSubmitting(false);
    if (error) {
      toast(error.message, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: style['toast_body_error'],
      });
    } else {
      SetFormData({
        firstName: '',
        lastName: '',
        company: '',
        companyAddress: '',
        email: '',
        phoneNumber: '',
        password: '',
      });
      const rs: any = result;
      toast(rs.message, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: rs.status ? style['toast_body'] : style['toast_body_error'],
      });
      return;
    }
  };
  const handleInputChange = (e: FieldChangePayload) => {
    if (selectedType === 'individual') {
      SetIndividualFormData({
        ...individualFormData,
        [e.field]: e.value,
      });
    } else {
      SetFormData({
        ...formData,
        [e.field]: e.value,
      });
    }

    validatePassword(e.value);
  };

  const [formType, setFormType] = useState('individual');

  const handleSelectType = (type) => {
    setSelectedType(type);
  };

  const handleContinue = () => {
    if (selectedType === 'individual' || selectedType === 'business') {
      setFormType(selectedType);
      setStep(step + 1);
    }
  };

  const [passwordValidations, setPasswordValidations] = useState({
    hasLowerCase: false,
    hasUpperCase: false,
    hasNumber: false,
    hasSpecialChar: false,
  });

  const validatePassword = (password: string) => {
    const hasLowerCase = /[a-z]/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*]/.test(password);

    setPasswordValidations({
      hasLowerCase,
      hasUpperCase,
      hasNumber,
      hasSpecialChar,
    });
  };

  const renderStep1 = () => {
    return (
      <div className={style['step1-container']}>
        <h2>Who is this account for?</h2>
        <div
          className={`${style['individual-reg']} ${
            selectedType === 'individual' ? style['actived'] : ''
          }`}
          onClick={() => handleSelectType('individual')}
        >
          <div className={style['individual-reg-log']}>
            <img src={users} alt="user" />
          </div>
          <div>
            <h3>Individual</h3>
            <p>Retailers, freelancers, gig workers.</p>
          </div>
        </div>
        <div
          className={`${style['business-reg']} ${
            selectedType === 'business' ? style['actived'] : ''
          }`}
          onClick={() => handleSelectType('business')}
        >
          <div className={style['individual-reg-log']}>
            <img src={briefcase} alt="brief" />
          </div>
          <div>
            <h3>Business</h3>
            <p>MSME, SME, Large Organization</p>
          </div>
        </div>
        <button className={style['cont-stage1']} onClick={handleContinue}>
          Continue <img src={arrow} alt="forward" />{' '}
        </button>
      </div>
    );
  };

  const renderStep2 = () => {
    return (
      <div>
        {selectedType === 'individual' ? (
          <div className={style['step2-individual-container']}>
            <div className={style['custom-input']}>
              <label>First Name</label>
              <input
                type="text"
                placeholder="First Name"
                value={individualFormData.firstName}
                onChange={(e) =>
                  handleInputChange({
                    field: 'firstName',
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div className={style['custom-input']}>
              <label>Last Name</label>
              <input
                type="text"
                placeholder="Last Name"
                value={individualFormData.lastName}
                onChange={(e) =>
                  handleInputChange({
                    field: 'lastName',
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div className={style['custom-input']}>
              <label>Email</label>
              <input
                type="email"
                placeholder="Email"
                value={individualFormData.email}
                onChange={(e) =>
                  handleInputChange({
                    field: 'email',
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div className={style['custom-input']}>
              <label>Phone Number</label>
              <input
                type="text"
                placeholder="Phone Number"
                value={individualFormData.phoneNumber}
                onChange={(e) =>
                  handleInputChange({
                    field: 'phoneNumber',
                    value: e.target.value,
                  })
                }
              />
            </div>
          </div>
        ) : (
          <div className={style['step2-individual-container']}>
            <div className={style['custom-input']}>
              <label>First Name</label>
              <input
                type="text"
                placeholder="First Name"
                value={formData.firstName}
                onChange={(e) =>
                  handleInputChange({
                    field: 'firstName',
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div className={style['custom-input']}>
              <label>Last Name</label>
              <input
                type="text"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={(e) =>
                  handleInputChange({
                    field: 'lastName',
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div className={style['custom-input']}>
              <label>Business Name</label>
              <input
                type="text"
                placeholder="Business Name"
                value={formData.company}
                onChange={(e) =>
                  handleInputChange({
                    field: 'company',
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div className={style['custom-input']}>
              <label>Company Address</label>
              <input
                type="text"
                placeholder="Company Address"
                value={formData.companyAddress}
                onChange={(e) =>
                  handleInputChange({
                    field: 'companyAddress',
                    value: e.target.value,
                  })
                }
              />
            </div>
          </div>
        )}

        {/* <button onClick={() => setStep(1)} className={style['cont-stage2']}>
          Back
        </button> */}
        <button className={style['cont-stage2']} onClick={handleContinue}>
          Continue <img src={arrow} alt="forward" />{' '}
        </button>
      </div>
    );
  };

  const renderStep3 = () => {
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <div className={style['step2-individual-container']}>
            {selectedType === 'business' && (
              <>
                <div className={style['custom-input']}>
                  <label>Email</label>
                  <input
                    type="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={(e) =>
                      handleInputChange({
                        field: 'email',
                        value: e.target.value,
                      })
                    }
                  />
                </div>
                <div className={style['custom-input']}>
                  <label>Phone Number</label>
                  <input
                    type="tel"
                    placeholder="Phone Number"
                    value={formData.phoneNumber}
                    onChange={(e) =>
                      handleInputChange({
                        field: 'phoneNumber',
                        value: e.target.value,
                      })
                    }
                  />
                </div>
              </>
            )}
            <div className={style['custom-input']}>
              <label>Password</label>
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                value={
                  selectedType === 'individual'
                    ? individualFormData.password
                    : formData.password
                }
                onChange={(e) =>
                  handleInputChange({
                    field: 'password',
                    value: e.target.value,
                  })
                }
              />
              <img
                src={eye}
                alt="toggle password visibility"
                className={`${style['eye-icon']} ${
                  selectedType === 'business' && step === 3
                    ? style['eye-icon-business']
                    : ''
                }`}
                onClick={handleTogglePassword}
              />
            </div>
            <div className={style['custom-input']}>
              <label>Confirm Password</label>
              <input
                type="password"
                placeholder="Confirm Password"
                value={
                  selectedType === 'individual'
                    ? individualFormData.password
                    : formData.password
                }
                onChange={(e) =>
                  handleInputChange({
                    field: 'password',
                    value: e.target.value,
                  })
                }
              />
              <img
                src={eye}
                alt="toggle password visibility"
                className={`${style['eye-icon1']} ${
                  selectedType === 'business' && step === 3
                    ? style['eye-icon-business1']
                    : ''
                }`}
                onClick={handleTogglePassword}
              />
            </div>
            <div className={style['password-validation-container']}>
              <div>
                {passwordValidations.hasLowerCase ? (
                  <img src={green} alt="check" />
                ) : (
                  <img src={gary} alt="check" />
                )}
                <p>At least one lowercase letter</p>
              </div>
              <div>
                {passwordValidations.hasUpperCase ? (
                  <img src={green} alt="check" />
                ) : (
                  <img src={gary} alt="check" />
                )}
                <p>At least one uppercase letter</p>
              </div>
              <div>
                {passwordValidations.hasNumber ? (
                  <img src={green} alt="check" />
                ) : (
                  <img src={gary} alt="check" />
                )}
                <p>At least one number</p>
              </div>
              <div>
                {passwordValidations.hasSpecialChar ? (
                  <img src={green} alt="check" />
                ) : (
                  <img src={gary} alt="check" />
                )}
                <p>At least one special character</p>
              </div>
            </div>
            {/* <button onClick={() => setStep(2)} className={style['cont-stage1']}>
              Back
            </button> */}
            <button type="submit" className={style['cont-stage1']}>
              Sign Up
            </button>
          </div>
        </form>
      </div>
    );
  };
  return (
    <div>
      <form>
        <div className={style['signup-container']}>
          <div className={style['signup-image-container']}>
            <a href="/" className={style['navigation']}>
              <img src={AwabahLogo} alt="logo" />
            </a>
          </div>
          <a href="/">
            <img src={Logo} alt="logo" className={style['logo-sign-up']} />
          </a>

          <div
            className={`${style['get-started-container']} ${
              selectedType === 'business' && step === 3
                ? style['business-step3']
                : ''
            }`}
          >
            <h1>Get Started</h1>
            <p className={style['p-sign']}>Sign up for Awabah to get started</p>
            <div className={style['progress-indicators']}>
              <div className={style['progess-title']}>
                <div
                  className={`${style['progress-circle']} ${
                    step >= 1 ? style['active'] : ''
                  }`}
                >
                  <img src={check} />
                </div>
              </div>
              <div
                className={
                  step <= 3
                    ? style['progress-line-0A140F']
                    : style['progress-line-B9BDBB']
                }
              ></div>
              <div className={style['progess-title']}>
                <div
                  className={`${style['progress-circle']} ${
                    step >= 2 ? style['active'] : ''
                  }`}
                >
                  <img src={check} />
                </div>
              </div>
              <div
                className={
                  step === 3
                    ? style['progress-line-0A140F']
                    : style['progress-line-B9BDBB']
                }
              ></div>
              <div className={style['progess-title']}>
                <div
                  className={`${style['progress-circle']} ${
                    step >= 3 ? style['active'] : ''
                  }`}
                >
                  <img src={check} />
                </div>
              </div>
            </div>
            <div className={style['progress-indicator-title']}>
              <p>Account info</p> <p style={{ width: '24%' }}>Personal info</p>
              <p>Additional Info</p>
            </div>
            <div>
              {step === 1 && renderStep1()}
              {step === 2 && renderStep2()}
              {step === 3 && renderStep3()}
            </div>
            <div className={style['foter-sigup-text']}>
              {' '}
              <p>
                Already have an account with Awabah?{' '}
                <span>
                  <a href={`${LOGIN_URL}`} className={style['login']}>
                    Sign In
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default EssSignUp;
